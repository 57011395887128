import React from "react";
import { useJsonData } from "../../../data/JsonDataContext";
import { useTranslation } from "react-i18next";

const ProductFeature = ({ componentTitle }) => {
  
  const data = useJsonData();
  const { t, i18n } = useTranslation();
  const productToDisplay = data.find((product) => product.title === componentTitle);
  const images=productToDisplay?.images

  return (
    <div className="bg-white">
      <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-5">
        <div>
          <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8 text-center md:text-left">
              <div className="border-t border-gray-200 pt-4 ">
                <dt className="font-medium text-gray-900">{t("product_key_features_title")}</dt>
                {productToDisplay[i18n.language].key_features.map((feature,index) => <dd key={index} className="mt-2 text-sm text-gray-500">{feature}</dd>)}
              </div>
               <div className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">{t("product_technical_specifications_title")}</dt>
                {Object.keys(productToDisplay[i18n.language].technical_specifications).map((key) => (
                  <dd className="mt-2 text-sm text-gray-500" key={key}><strong>{key}:</strong>{productToDisplay[i18n.language].technical_specifications[key]}</dd>
                ))}
              </div>
              <div className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">{t("product_safety_features_title")}</dt>
                {productToDisplay[i18n.language].safety_features.map((feature,index) => <dd key={index} className="mt-2 text-sm text-gray-500">{feature}</dd>)}
              </div>
              <div className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">{t("product_benefits_title")}</dt>
                {productToDisplay[i18n.language].benefits.map((feature,index) => <dd key={index} className="mt-2 text-sm text-gray-500">{feature}</dd>)}
              </div>
              <div className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">{t("product_maintenance_requirements_title")}</dt>
                {productToDisplay[i18n.language].maintenance_requirements.map((feature,index) => <dd key={index} className="mt-2 text-sm text-gray-500">{feature}</dd>)}
              </div>
              <div className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">{t("product_warranty_and_support")}</dt>
                {productToDisplay[i18n.language].warranty_and_support.map((feature,index) => <dd key={index} className="mt-2 text-sm text-gray-500">{feature}</dd>)}
              </div>
          </dl>
        </div>
        <div className="grid md:grid-cols-3 grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
          

{images.length > 0 ? (
            images.map((image, index) => (
              <img
                key={index}
                alt={`Product image ${index + 1}`}
                src={image}
                className="rounded-lg bg-gray-200 md:h-64 md:w-64 h-40 w-40 object-contain"
              />
            ))
          ) : (
            <p className="text-sm text-gray-500">No images available for this product.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductFeature;
