import React from "react";
import Button from "../../../components/ui component/Button";
import { useTranslation } from "react-i18next";


const ProductFilter = ({ titles, areas, selectedTitle, setSelectedTitle, selectedArea, setSelectedArea }) => {
  const { t } = useTranslation();
  const areaTranslationKeys = {
    skin: "treatmentType_Opt1",
    hair: "treatmentType_Opt2",
    body: "treatmentType_Opt3",
    "pelvic floor": "treatmentType_Opt4",
    others: "treatmentType_Opt5",
  };
  
  return (
    <div className="flex justify-center space-x-4 mt-32 md:gap-64">
      <div>
        <label className="block text-gray-700 font-semibold mb-2 text-base">
         {t("label_name")} 
        </label>
        <select
        value={selectedTitle}
        onChange={(e)=> setSelectedTitle(e.target.value)}
          className="block  px-4 py-4 rounded-md border border-gray-300  bg-CostumPurple text-white md:w-56 w-32"
         
        >
          <option value="">{t("All")}</option>
          {titles.map((title) => (
            <option key={title} value={title}>
              {title}
            </option>
          ))}
            
        </select>
      </div>


      <div>
        <label className="block text-gray-700 font-semibold mb-2">
  {t("label_treatment")}      </label>
        <select
          className="block  px-4 py-4 rounded-md border border-gray-300 bg-CostumPurple text-white md:w-56 w-32"
          value={selectedArea}
          onChange={(e)=> setSelectedArea(e.target.value)}
        >
          <option value="">{t("All")}</option>
          {areas.map((area) => (
            <option key={area} value={area}>
              {t(areaTranslationKeys[area.toLowerCase()])}
              </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default ProductFilter;
