import React from "react";
import Button from "../../../components/ui component/Button";
import { Link } from "react-router-dom";
import { useJsonData } from "../../../data/JsonDataContext";
import { useBannerData } from "../../../data/BannerDataContext";
import { useTranslation } from "react-i18next";

const Banner = ({ componentTitle }) => {

  const Banners = useBannerData();
  const data = useJsonData();
  const { t, i18n } = useTranslation();


  // Exclude Banners[2] and create a new array
  const productBanner = Banners.filter((_, index) => index !== 7);

  // Prevent rendering if data is unavailable
  if (!Banners || Banners.length === 0) {
    return <h1>Loading banners...</h1>;
  }
  
// Dynamically map product titles to banner images
const bannerMapping = data.reduce((mapping, product, index) => {
  mapping[product.title] = Banners[index] || Banners[0]; // Default to the first banner if no match
  return mapping;
}, {});

// Get the banner for the current product
const currentBanner = bannerMapping[componentTitle] || Banners[0];


  // Find the product to display based on the component title
  const productToDisplay = data.find(
    (product) => product.title === componentTitle
  );


  const desc = productToDisplay[i18n.language].description.map((paraph, index) => (
    <li key={index} className="text-[1.2rem] text-white md:mt-2">
      {paraph}
    </li>
  ));
  return (
    <div>
      <section
      className="relative bg-cover bg-center py-8 object-contain"
      style={{
        backgroundImage: `url("${currentBanner}")`,
      }}
    >
      <div className="absolute inset-0 bg-white bg-opacity-35"></div>
      <div className="absolute inset-0 bg-CostumPurple bg-opacity-60"></div>


      <div className="banner_text relative inset-0 flex flex-col overflow-auto text-white lg:mb-5 mt-40 md:mt-25 left-4 md:left-28 md:w-[60%] w-[95%] ">
        <h1 className="text-4xl font-bold md:mb-4 mb-8">
          {productToDisplay?.title || "Default Title"}
        </h1>

        <ul className="text-black">{desc}</ul>
        <div className="mt-16 lg:mb-5">
          <Link to="/Contact">
          <button className="bg-white border-2 border-white text-CostumPurple py-2 px-5 rounded-full hover:bg-CostumPurple hover:border-CostumPurple text-base hover:text-white">
            Contact Us
            </button>
          </Link>
        </div>
      </div>
    </section>
    </div>
  );
};

export default Banner;
