import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { submitContactForm } from "../services/apiService";

import { ToastContainer, toast } from "react-toastify";
import { useJsonData } from "../data/JsonDataContext";
import { PopupButton } from "react-calendly";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const data=useJsonData();
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: "",
    phone_number: "",
    country: "",
    city: "",
    product: "",
    message: "",
  });
  const productName = [...new Set(data.map((product) => product.title))];

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  if (value.trim() !== "") {
    setErrors((prevErrors) => {
      const { [name]: removedError, ...restErrors } = prevErrors;
      return restErrors;
    });
  }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name =  t("errors_name");
    if (!formData.phone_number) newErrors.phone_number =  t("errors_phone");
    if (!formData.country) newErrors.country = t("errors.country");
    if (!formData.city) newErrors.city =  t("errors.city");
    if (!formData.product) newErrors.product =  t("error_product");
    if (!formData.message) newErrors.message =  t("error_message");

    return newErrors;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
  
    if (Object.keys(validationErrors).length === 0) {
      try {
        submitContactForm(formData);
        setFormData({
          name: "",
          phone_number: "",
          country: "",
          city: "",
          product: "",
          message: "",
        });
        toast.success("Form submitted successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {
        toast.error("Failed to submit the form. Please try again.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex justify-center items-center px-5 md:px-14 pb-20">
      <ToastContainer />

      <div className="container mx-auto p-1 md:p-6 md:flex md:justify-between mt-28">
        <div className="md:w-1/2 p-6">
          <h2 className="text-4xl  mb-4"><span className="font-bold">{t("contact_us_page_booking_title")}</span>  </h2>
          <p className="text-gray-600 mb-6">
            {t("contact_us_page_booking_text")}
          </p>

          <div className="mt-14">
          <PopupButton
        url="https://calendly.com/contact-o-risha/new-meeting"
        styles={{
          backgroundColor: '#5C2B82',
          padding:'10px 18px',
          color:'white',
          borderRadius:'10px'
        }}
        rootElement={document.getElementById("root")}
        text={t("contact_us_page_schedule_meeting_button")}
      />
          </div>
<div className="mt-7"><h3 className="text-xl font-semibold">Email</h3>
<a href="mailto:contact@o-risha.com" className="">contact@o-risha.com</a>       
</div>
<div className="mt-7"><h3 className="text-xl font-semibold">Phone</h3>
<a href="tel:(438) 367-9453" className="">(438) 367-9453</a>        
</div>      

          
         
        </div>
        <div className="p-6 bg-white md:w-1/2  shadow-lg rounded-lg ">
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 gap-4 ">
              <div>
                <label className="block text-gray-700"> {t("Name")} </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full p-2 mt-1 border rounded-lg focus:outline-none "
                  placeholder={t("placeholder_name")}
                />
                {errors.name && <p className="text-red-500">{errors.name}</p>}
              </div>
              <div>
                <label className="block text-gray-700"> {t("Phone Number")} </label>
                <input
                  type="text"
                  name="phone_number"
                  value={formData.phone_number}
                  onChange={handleChange}
                  className="w-full p-2 mt-1 border rounded-lg focus:outline-none "
                  placeholder={t("placeholder_phone")}
                />
                {errors.phone_number && <p className="text-red-500">{errors.phone_number}</p>}
              </div>
              <div>
                <label className="block text-gray-700"> {t("Country")} </label>
                <input
                  type="text"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  className="w-full p-2 mt-1 border rounded-lg focus:outline-none"
                  placeholder={t("placeholder_country")}
                />
                {errors.country && (
                  <p className="text-red-500">{errors.country}</p>
                )}
              </div>
              <div>
                <label className="block text-gray-700"> {t("City")} </label>
                <input
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  className="w-full p-2 mt-1 border rounded-lg focus:outline-none 
                  "
                  placeholder={t("placeholder_city")}
                />
                {errors.city && <p className="text-red-500">{errors.city}</p>}
              </div>
              <div>
                <label className="block text-gray-700"> {t("Product")} </label>
                <select
                  name="product"
                  value={formData.product}
                  onChange={handleChange}
                  className="w-full p-2 mt-1 border rounded-lg focus:outline-none  
                  "
                >
                  <option value="" > <span className="text-gray-300"> {t("placeholder_product")} </span></option>
                  {productName.map((option,index) => (
                    <option key={index} value={option}>{option} </option>
                  ))}
                </select>
                {errors.product && (
                  <p className="text-red-500">{errors.product}</p>
                )}
              </div>
              <div>
                <label className="block text-gray-700"> {t("Message")} </label>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  className="w-full p-2 mt-1 border rounded-lg focus:outline-none"
                  placeholder={t("placeholder_message")}
                ></textarea>
                {errors.message && (
                  <p className="text-red-500">{errors.message}</p>
                )}
              </div>
            </div>
            <div className="mt-6">
              <button
                type="submit"
                className="w-full bg-CostumPurple text-white p-3 rounded-lg hover:bg-purple-600 transition"
              >
               {t("Submit")} 
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
